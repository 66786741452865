import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { api } from '../api/api';
import WebApp from '@twa-dev/sdk';

const useChatStore = create(
  devtools((set, get) => ({
    chats: [],
    currentChat: null,
    messages: [],
    ws: null,

    initWebSocket: () => {
      const telegramId = WebApp.initDataUnsafe?.user?.id;
      if (!telegramId) {
        console.error('Не удалось получить telegramId из WebApp.');
        return;
      }

      const ws = new WebSocket(`wss://api.loonw-space.com?telegramId=${telegramId}`);
      
      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.chatId === get().currentChat?._id) {
          set((state) => ({
            messages: [...state.messages, data],
          }));
        }
      };

      ws.onclose = () => console.log('WebSocket connection closed.');
      set({ ws });
    },

    fetchChats: async () => {
      const telegramId = WebApp.initDataUnsafe?.user?.id;
      if (!telegramId) {
        console.error('Не удалось получить telegramId из WebApp.');
        return;
      }

      try {
        const response = await api.get(`/chats/${telegramId}`);
        set({ chats: response.data });
      } catch (error) {
        console.error('Ошибка при загрузке чатов:', error);
      }
    },

    fetchChatById: async (chatId) => {
      try {
        const response = await api.get(`/chats/chat/${chatId}`);
        set({
          currentChat: response.data,
          messages: response.data.messages,
        });
      } catch (error) {
        console.error('Ошибка при загрузке чата:', error);
      }
    },

    sendMessage: (chatId, text) => {
      const telegramId = WebApp.initDataUnsafe?.user?.id;
      if (!telegramId) {
        console.error('Не удалось получить telegramId из WebApp.');
        return;
      }

      const { ws } = get();
      if (ws) {
        ws.send(JSON.stringify({ chatId, senderId: telegramId, text }));
        set((state) => ({
          messages: [...state.messages, { chatId, senderId: telegramId, text, createdAt: new Date() }],
        }));
      }
    },
  }))
);

export default useChatStore;
