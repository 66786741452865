import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useChatStore from '../../store/chatStore';

function ChatList() {
  const { chats, fetchChats, fetchChatById } = useChatStore();
  const navigate = useNavigate();

  useEffect(() => {
    fetchChats();
  }, [fetchChats]);

  const handleChatClick = async (chatId) => {
    await fetchChatById(chatId); // Загружаем данные конкретного чата
    navigate(`/chat/${chatId}`);
  };

  return (
    <div className="ChatList">
      {chats.map((chat) => (
        <div key={chat._id} onClick={() => handleChatClick(chat._id)} className="chat">
          <h3>{chat.participants.find((p) => p._id !== chat._id)?.nickname}</h3>
        </div>
      ))}
    </div>
  );
}

export default ChatList;
