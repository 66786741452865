import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useChatStore from '../store/chatStore';

function Chat() {
  const { chatId } = useParams();
  const { currentChat, messages, sendMessage, fetchChatById, initWebSocket } = useChatStore();
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    initWebSocket(); // Инициализируем WebSocket
    fetchChatById(chatId); // Загружаем данные чата при открытии
  }, [chatId, fetchChatById, initWebSocket]);

  const handleSendMessage = () => {
    sendMessage(chatId, inputText);
    setInputText('');
  };

  return (
    <div className="Chat">
      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index} className={msg.senderId === currentChat?.participants[0]._id ? 'sent' : 'received'}>
            {msg.text}
          </div>
        ))}
      </div>
      <input
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
      />
      <button onClick={handleSendMessage}>Отправить</button>
    </div>
  );
}

export default Chat;
